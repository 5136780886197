import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sediment Transport`}</h1>
    <h2>{`Sediment Transport Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1XMA_PPqrcNgeOfHj5nBnzq789c88LkDm"
      }}>{`Download: Sediment transport technology by Daryl B. Simons and Fuat Senturk (Full Book)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1eArtIHFaP03t0zDH_qnUKiQlMgaXy4Xj"
      }}>{`Download: Summary and use of selected fluvial sediment discharge formulas by Steven and Yang USGS`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/sediment-transport-lab-manual/"
      }}>{`Sediment transport lab manual`}</a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      